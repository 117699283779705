import MyHeader from '../../components/header';
import React, { ReactElement, useState } from 'react';
import { Affix, Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { motion } from 'framer-motion';

import { pageAnimation } from '../../utils/animations';

import './index.less';
import { getArticleByIdApi } from '../../request/api';

type MenuItem = Required<MenuProps>['items'][number];
const { Content, Sider } = Layout;

interface IRes {
    errCode?: number;
    message?: string;
    data?: any;
}

const CoursePage: React.FC = (): ReactElement => {
    // hooks 声明
    const [top] = useState(10);
    const [mask, setMask] = useState(true);
    const [course, setCourse] = useState(
        'https://player.bilibili.com/player.html?aid=676395459&bvid=BV1yU4y1u7QF&cid=436897771&page=1&high_quality=1',
    );

    // 编辑器内容
    const [html, setHtml] = useState('');

    // 图文教程标题
    // const items = [
    //     { label: '视频教程', key: '1' },
    //     { label: '图文教程', key: '2' },
    // ];

    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const items: MenuProps['items'] = [
        getItem('视频教程', 'menu', null, null),
        getItem('图文教程', 'menu2', null, [
            getItem('Rhino工具讲解', 'item-1', null, [
                getItem('·Rhino初始设置', '0'),
                getItem('·Rhino矢量标注工具讲解', 'item-1-2', null, [
                    getItem('·内插点曲线工具', '1'),
                    getItem('·圆形工具', '2'),
                    getItem('·椭圆工具', '3'),
                    getItem('·矩形工具', '4'),
                    getItem('·复制工具', '5'),
                    getItem('·缩放工具', '6'),
                    getItem('·修剪工具', '7'),
                    getItem('·旋转工具', '8'),
                    getItem('·镜像工具', '9'),
                    getItem('·阵列工具', '10'),
                    getItem('·打开点工具', '11'),
                    getItem('·偏移曲线工具', '12'),
                    getItem('·尺寸标注工具', '13'),
                    getItem('·多重直线工具', '14'),
                    getItem('·垂直居中工具', '15'),
                ]),
            ]),
            getItem('矢量化图形勾线指导', 'item-2', null, [
                getItem('·处理复杂的叠压关系', '16'),
                getItem('·线稿两种处理方式-莲花的矢量化', '17'),
                getItem('·不规则图形矢量化-仙鹤', '18'),
                getItem('·处理重合复杂线段-吉祥结矢量化', '19'),
                getItem('·莲花座的矢量化', '20'),
                getItem('·处理多根线段偏移-小鸟矢量化', '21'),
                getItem('·图形倾斜问题-权杖矢量化', '22'),
                getItem('·处理阵列后位置偏差-花碗的矢量化', '23'),
                getItem('·巧用阵列替复制-如意宝矢量化', '24'),
                getItem('·相似图形处理-如意树冠矢量化', '25'),
                getItem('·处理偏移曲线自动闭合问题-花骨朵的矢量化', '26'),
                getItem('·图形和偏移曲线的匹配处理-海螺矢量化', '27'),
                getItem('·镜像图和原图不一致的处理-莲花的矢量化', '28'),
                getItem('·阵列图和原图不一致-太阳的矢量化', '29'),
            ]),
            getItem('图像导出、置入及存储', 'item-3', null, [
                getItem('·在AI中置入原图', '30'),
                getItem('·Rhino文件转换为AI文件格式', '31'),
                getItem('·存储源文件', '32'),
                getItem('·如何储存图片格式', '33'),
                getItem('·如何导出矢量图形', '34'),
            ]),
            getItem('矢量图形上色', 'item-4', null, [
                getItem('·太阳上色示范', '35'),
                getItem('·雕上色示范', '36'),
                getItem('·海螺上色示范', '37'),
                getItem('·兔子上色示范', '38'),
                getItem('·仙鹤上色示范', '39'),
                getItem('·渐变-贡碗上色示范', '40'),
                getItem('·补线示范', '41'),
            ]),
            getItem('矢量化规范标准', 'item-5', null, [
                getItem('·矢量化合格标准', '42'),
                getItem('·矢量化优秀标准', '43'),
                getItem('·单个元素复刻标准', '44'),
                getItem('·唐卡元素提取工作量单位标准', '45'),
            ]),
        ]),
    ];

    // 图文教程标题点击事件
    const onMenuClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        if (e.key == 'menu') {
            setMask(true);
        } else {
            setMask(false);
            getArticleByIdApi({ id: Number(e.key) }).then((res: IRes) => {
                setHtml(res.data.content);
            });
        }
    };

    // 视频教程标题
    const courseList = [
        {
            label: '1. 初次使用rhino设置步骤',
            key: 'https://player.bilibili.com/player.html?aid=676395459&bvid=BV1yU4y1u7QF&cid=436897771&page=1&high_quality=1',
        },
        {
            label: '2. 工具讲解——1.内插点工具',
            key: 'https://player.bilibili.com/player.html?aid=718955478&bvid=BV1hQ4y1S7ZB&cid=436904196&page=1&high_quality=1',
        },
        {
            label: '3. 工具讲解——2.圆形工具',
            key: 'https://player.bilibili.com/player.html?aid=506429169&bvid=BV11u411o7G4&cid=436904774&page=1&high_quality=1',
        },
        {
            label: '4. 工具讲解——3.椭圆工具',
            key: 'https://player.bilibili.com/player.html?aid=591384447&bvid=BV18q4y1R73C&cid=436906343&page=1&high_quality=1',
        },
        {
            label: '5. 工具讲解——4.矩形工具',
            key: 'https://player.bilibili.com/player.html?aid=891431501&bvid=BV1AP4y1j7p5&cid=436906668&page=1&high_quality=1',
        },
        {
            label: '6. 工具讲解——5.复制工具',
            key: 'https://player.bilibili.com/player.html?aid=933890786&bvid=BV17T4y1d7LU&cid=436907041&page=1&high_quality=1',
        },
        {
            label: '7. 工具讲解——6.缩放工具',
            key: 'https://player.bilibili.com/player.html?aid=463935333&bvid=BV14L41137kq&cid=436907441&page=1&high_quality=1',
        },
        {
            label: '8. 工具讲解——7.修剪工具',
            key: 'https://player.bilibili.com/player.html?aid=506436791&bvid=BV1Zu411o75Z&cid=436907759&page=1&high_quality=1',
        },
        {
            label: '9. 工具讲解——8.旋转工具',
            key: 'https://player.bilibili.com/player.html?aid=591381382&bvid=BV1hq4y1R7Nt&cid=436908051&page=1&high_quality=1',
        },
        {
            label: '10. 工具讲解——9.镜像工具',
            key: 'https://player.bilibili.com/player.html?aid=891425043&bvid=BV1wP4y1j7kp&cid=436908303&page=1&high_quality=1',
        },
        {
            label: '11. 工具讲解——10.阵列工具',
            key: 'https://player.bilibili.com/player.html?aid=676514084&bvid=BV1HU4y1g7Vf&cid=437130398&page=1&high_quality=1',
        },
        {
            label: '12. 工具讲解——11.打开点工具',
            key: 'https://player.bilibili.com/player.html?aid=934072768&bvid=BV1pT4y197fk&cid=437130675&page=1&high_quality=1',
        },
        {
            label: '13. 工具讲解——12.偏移曲线工具',
            key: 'https://player.bilibili.com/player.html?aid=251560188&bvid=BV17v411M7b3&cid=437130896&page=1&high_quality=1',
        },
        {
            label: '14. 工具讲解——13.尺寸标注工具',
            key: 'https://player.bilibili.com/player.html?aid=849088504&bvid=BV1JL4y1v76c&cid=437131104&page=1&high_quality=1',
        },
        {
            label: '15. 工具讲解——14.多重直线工具',
            key: 'https://player.bilibili.com/player.html?aid=379000354&bvid=BV1kf4y1u76T&cid=437131348&page=1&high_quality=1',
        },
        {
            label: '16. 工具讲解——15.垂直居中工具',
            key: 'https://player.bilibili.com/player.html?aid=719097122&bvid=BV1kQ4y1m7La&cid=437131545&page=1&high_quality=1',
        },
        {
            label: '17. 复杂的叠压关系如何处理——耳坠的矢量化',
            key: 'https://player.bilibili.com/player.html?aid=676683897&bvid=BV1eU4y1M7c9&cid=441111782&page=1&high_quality=1',
        },
        {
            label: '18. 线稿处理方法的两种争论——莲花的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=209263251&bvid=BV1gh41147yQ&cid=447558427&page=1&high_quality=1',
        },
        {
            label: '19. 当遇到不规则的图形如何处理——仙鹤的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=421810122&bvid=BV1H3411t7Ee&cid=447555905&page=1&high_quality=1',
        },
        {
            label: '20. 线段重合复杂如何处理——吉祥结的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=849385754&bvid=BV1xL4y1p7bB&cid=448407860&page=1&high_quality=1',
        },
        {
            label: '21. 小试牛刀——莲花座的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=506974787&bvid=BV1Zg411N7cp&cid=448408471&page=1&high_quality=1',
        },
        {
            label: '22. 多根线段如何同时偏移处理——小鸟的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=934429244&bvid=BV1jM4y1P7if&cid=449605639&page=1&high_quality=1',
        },
        {
            label: '23. 图形倾斜问题-权杖矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=506943642&bvid=BV12g411N7b2&cid=449000282&page=1&high_quality=1',
        },
        {
            label: '24. 处理阵列后位置偏差-花碗的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=721918856&bvid=BV1eS4y1R7p3&cid=449606396&page=1&high_quality=1',
        },
        {
            label: '25. 如何确定勾线起点-如意宝矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=421929971&bvid=BV1g3411b78h&cid=450270600&page=1&high_quality=1',
        },
        {
            label: '26. 相似图形处理-如意树冠矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=806971519&bvid=BV1F34y1R7YG&cid=450271278&page=1&high_quality=1',
        },
        {
            label: '27. 处理偏移曲线自动闭合问题-花骨朵的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=891877495&bvid=BV1mP4y1G78v&cid=450945513&page=1&high_quality=1',
        },
        {
            label: '28. 图形和偏移曲线的匹配处理-海螺矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=336966330&bvid=BV1jR4y147dB&cid=450944600&page=1&high_quality=1',
        },
        {
            label: '29. 镜像图和原图不一致的处理-莲花的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=294588349&bvid=BV1iF41187iP&cid=452825278&page=1&high_quality=1',
        },
        {
            label: '30. 阵列图和原图不一致-太阳的矢量化示范',
            key: 'https://player.bilibili.com/player.html?aid=507065555&bvid=BV19g411A73v&cid=452825964&page=1&high_quality=1',
        },
        {
            label: '31. “照猫画虎”的前提——如何置入原图',
            key: 'https://player.bilibili.com/player.html?aid=677004095&bvid=BV1hU4y1T7W5&cid=453413196&page=1&high_quality=1',
        },
        {
            label: '32. Rhino文件转换为AI文件格式',
            key: 'https://player.bilibili.com/player.html?aid=764596012&bvid=BV1Rr4y1Q7MA&cid=453412647&page=1&high_quality=1',
        },
        {
            label: '33. 存储源文件',
            key: 'https://player.bilibili.com/player.html?aid=252120527&bvid=BV1cY411x7Sr&cid=454037243&page=1&high_quality=1',
        },
        {
            label: '34. 如何储存图片格式',
            key: 'https://player.bilibili.com/player.html?aid=892220318&bvid=BV1AP4y1G7bc&cid=455508927&page=1&high_quality=1',
        },
        {
            label: '35. “栩栩如生”的秘诀——上色初示范',
            key: 'https://player.bilibili.com/player.html?aid=592077122&bvid=BV1Lq4y1z7fA&cid=454036851&page=1&high_quality=1',
        },
        {
            label: '36. 《神雕侠侣》再现——大雕上色',
            key: 'https://player.bilibili.com/player.html?aid=294739623&bvid=BV1jF411z7Wp&cid=455509280&page=1&high_quality=1',
        },
        {
            label: '37. 海螺上色示范',
            key: 'https://player.bilibili.com/player.html?aid=592172621&bvid=BV1yq4y1z71w&cid=455949126&page=1&high_quality=1',
        },
        {
            label: '38. 既然“狡兔三窟”，那就逐个击破',
            key: 'https://player.bilibili.com/player.html?aid=764691428&bvid=BV1yr4y1S7H7&cid=455950076&page=1&high_quality=1',
        },
        {
            label: '39. 这是一个丑小鸭变成白天鹅的故事',
            key: 'https://player.bilibili.com/player.html?aid=507215648&bvid=BV1tg411P7fN&cid=456624527&page=1&high_quality=1',
        },
        {
            label: '40. 渐变的美妙初体验',
            key: 'https://player.bilibili.com/player.html?aid=849696014&bvid=BV1gL4y1H7yW&cid=457226959&page=1&high_quality=1',
        },
        {
            label: '41. 渐变的美妙初体验（二）',
            key: 'https://player.bilibili.com/player.html?aid=592194219&bvid=BV1Jq4y1q7sn&cid=457227415&page=1&high_quality=1',
        },
    ];

    // 阻止事件冒泡
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onCourseListClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // 切换视频教程
    const onCourseClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
        setCourse(e.key);
    };

    return (
        <div className="courseBody">
            <MyHeader current="item-3" />
            <div className="courseHeader-title">特色民族文化矢量标注流程</div>
            {/* <div className="courseHeader-title2">藏文化矢量图像元素处理流程</div> */}
            <div className="courseContent">
                <p>
                    目前学术界和工业界纷纷探索出诸多文化资源的矢量化转化方法，
                    但利用计算机自动生成这一传统方法形成的矢量化文件存在着识别不清、 轮廓抓取不精等弊端，
                    效果不佳且普适性差， 难以应用于实际开发。 因此， 通过人工辅助的计算机智能矢量标洼，
                    能够准确识别特色民族文化资源中的元素， 为后续建立特色民族文化矢量真值数据集提供大量数据资源。
                </p>
                <p>
                    特色民族文化矢量标注流程主要展示数字化资源的矢量化处理过程， 分为视频与图文两种方式，
                    以此满足复合型人才碎片化时间教育的需求。 通过标准化流程与通俗化的表达、 示范， 提升流程的可操作性，
                    降低矢量标注的准入门槛， 帮助不同专业的人才快速掌握标注方法， 并以规范化的格式进行元素标注，
                    提升标注效率和精度， 形成统一标准的数据集。
                    本地复合型人才的快速培养能够吸引数字文化产业的人才资源聚集，
                    而本地人才资源的归属感和对本土文化的熟悉程度都能使人才产生回归意愿，
                    为区域性产业链的加速发展提供人才资源， 最大化实现青海本地人才的价值输出。
                </p>
            </div>

            <motion.div
                className="courseComponent"
                exit="exit"
                initial="initial"
                animate="animate"
                variants={pageAnimation}
            >
                <div className="graphicContainer">
                    <Layout>
                        <Affix offsetTop={top}>
                            <Sider
                                breakpoint="lg"
                                collapsedWidth="0"
                                onBreakpoint={(broken) => {
                                    console.log(broken);
                                }}
                                onCollapse={(collapsed, type) => {
                                    console.log(collapsed, type);
                                }}
                            >
                                <div className="logo" />
                                <Menu
                                    theme="light"
                                    mode="inline"
                                    defaultSelectedKeys={['1']}
                                    items={items}
                                    onClick={onMenuClick}
                                />
                            </Sider>
                        </Affix>
                        <Layout>
                            <Content style={{ margin: '24px 16px 0' }}>
                                {mask ? (
                                    <div className="courseVideo">
                                        <iframe
                                            className="iframeContainer"
                                            src={course}
                                            scrolling="no"
                                            frameBorder="no"
                                            allowFullScreen={true}
                                            sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"
                                        />
                                        <div className="courseList" onClick={onCourseListClick}>
                                            <Menu
                                                items={courseList}
                                                defaultSelectedKeys={[courseList[0].key]}
                                                onClick={onCourseClick}
                                            />
                                            ;
                                        </div>
                                    </div>
                                ) : (
                                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                                        {/* <div className="courseItemTitle">初次使用rhino进行矢量化处理的调整步骤</div>
                                        <div className="courseItem">
                                            <img className="courseStepImg" src={c1_1} alt="course" />
                                            <div className="courseStep">
                                                <p>打开功能，点“TOP”旁的“倒三角”按钮，选择最大化</p>
                                            </div>
                                        </div>
                                        <div className="courseItem">
                                            <img className="courseStepImg" src={c2_1} alt="course" />
                                            <div className="courseStep">
                                                <p>
                                                    右键点击工具空白处，选择“新增标签”（可以自己设置名称）。在其他工具栏中找到常用的工具，并使用“shift+左键”拖入“新增标签”之中，提升勾线效率（各工具位置标明文档在“快捷工具位置”文档之中）
                                                </p>
                                            </div>
                                        </div>
                                        <div className="courseItem">
                                            <img className="courseStepImg" src={c3_1} alt="course" />
                                            <div className="courseStep">
                                                <p>左上角指令栏选择“工具”——“选项”——“格线”。</p>
                                            </div>
                                        </div> */}

                                        <div id="editor-content-view" dangerouslySetInnerHTML={{ __html: html }}></div>
                                    </div>
                                )}
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </motion.div>
        </div>
    );
};

export default CoursePage;
