/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState } from 'react';
import { Layout, Menu, MenuProps, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.less';
import { UserInfoApi } from '../../request/api';

type Props = {
    /** 当前所处页面key */
    current: string;
};

interface IRes {
    errCode?: number;
    message?: string;
    data?: any;
}

const { Header } = Layout;

const items = [
    { label: '网站首页', key: 'item-1' }, // 菜单项务必填写 key
    { label: '矢量真值数据集', key: 'item-2' },
    { label: '矢量标注流程', key: 'item-3' },
    { label: '矢量数据应用案例', key: 'item-4' },
    { label: '联系我们', key: 'item-5' },
];

/**
 *  problem:当Menu的mode设置为horizontal时，手机端会报错
 */
const MyMenu: React.FC<Props> = ({ current }): ReactElement => {
    const navigate = useNavigate();
    const onMenuClick: MenuProps['onClick'] = (e) => {
        // console.log('click');
        // const [current, setCurrent] = useState('item-1');
        switch (e.key) {
            case 'item-1':
                navigate('/index');
                // setCurrent('item-1');
                break;
            case 'item-2':
                navigate('/database');
                // UserInfoApi().then((res: IRes) => {
                //     if (res.errCode === 0) {
                //         navigate('/database');
                //     } else {
                //         message.warning(res.message, 1.5);
                //         setTimeout(() => {
                //             navigate('/login');
                //         }, 1500);
                //     }
                // });
                // setCurrent('item-2');
                break;
            case 'item-3':
                navigate('/course');
                // setCurrent('item-3');
                break;
            case 'item-4':
                navigate('/product');
                // setCurrent('item-4');
                break;
            case 'item-5': {
                navigate('/index');
                setTimeout(() => {
                    document.querySelector('.contacts-container').scrollIntoView({ behavior: 'smooth' });
                }, 1000);
                // setCurrent('item-1');
                break;
            }
            default: {
                break;
            }
        }
    };
    return (
        <Menu
            theme="dark"
            selectedKeys={[current]}
            defaultSelectedKeys={[current]}
            items={items}
            onClick={onMenuClick}
            mode="inline"
        />
    );
};

const MyHeader: React.FC<Props> = ({ current }): ReactElement => {
    return (
        <Header>
            <div className="title">
                {/* <div className="logo" /> */}
                <div className="name">特色民族文化矢量标注流程及矢量真值数据集平台</div>
            </div>
            <MyMenu current={current} />
        </Header>
    );
};

export default MyHeader;
