import MyHeader from '../../../components/header';
import React, { ReactElement } from 'react';
import { Affix, Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

import './index.less';

import p1 from '../../../assets/product/p4.png';
import p2 from '../../../assets/product/p5.png';
import p3 from '../../../assets/product/p6.png';
const { Content, Sider } = Layout;

const ProductDetail: React.FC = (): ReactElement => {
    function getItem(
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: MenuItem[],
        type?: 'group',
    ): MenuItem {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    }

    const productList: MenuProps['items'] = [
        getItem('双面真丝大方巾', 'menu', null, [
            getItem('· 黄金花园/雪域花园', 'item-1'),
            getItem('· 蓝莲花', 'item-2'),
            getItem('· 羚渡琼楼', 'item-3'),
            getItem('· 云山雾海', 'item-4'),
            getItem('· 绫罗绸缎', 'item-5'),
        ]),
        getItem('双面真丝中号方巾', 'menu2', null, [getItem('· 吉祥八宝（四色）', 'item-6')]),
        getItem('双面真丝小方巾', 'menu3', null, [
            getItem('· 如意宝树', 'item-7'),
            getItem('· 白象牡丹', 'item-8'),
            getItem('· 金蛇烛台', 'item-9'),
            getItem('· 松赞干布', 'item-10'),
            getItem('· 绫罗绸缎鎏金头盔', 'item-11'),
            getItem('· 宝蝶天降', 'item-12'),
            getItem('· 白螺物语', 'item-13'),
            getItem('· 西藏印象', 'item-14'),
            getItem('· 日月白象', 'item-15'),
        ]),
        getItem('双面真丝方巾', 'menu4', null, [getItem('· 日月白象', 'item-16'), getItem('· 龙凤呈祥', 'item-17')]),
    ];

    const onProductItemClick: MenuProps['onClick'] = (e) => {
        console.log('click ', e);
        document.querySelector('#' + e.key).scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="productDetailBody">
            <MyHeader current="item-4" />
            <div className="productHeader-title">特色民族文化艺术方巾</div>
            <Layout className="productDetailContainer">
                <Affix className="productAffix">
                    <Sider
                        breakpoint="lg"
                        collapsedWidth="0"
                        onBreakpoint={(broken) => {
                            console.log(broken);
                        }}
                        onCollapse={(collapsed, type) => {
                            console.log(collapsed, type);
                        }}
                    >
                        <div className="logo" />
                        <Menu
                            items={productList}
                            theme={'dark'}
                            mode="inline"
                            defaultSelectedKeys={['item-1']}
                            defaultOpenKeys={['menu', 'menu2', 'menu3', 'menu4']}
                            onClick={onProductItemClick}
                        />
                    </Sider>
                </Affix>
                <Layout>
                    <Content style={{ margin: '24px 16px 0' }}>
                        <div className="productDetailComponent">
                            <div className="productItem">
                                <div className="productItemTitle">雪域花园/黄金花园真丝双面大方巾</div>
                                <div className="productItemImageContainer">
                                    <div className="productItemImageContent">
                                        <img className="productItemImage" src={p1}></img>
                                        <div className="productItemDetail">
                                            <div className="itemLeft">产品详情</div>
                                            <div className="itemRight">
                                                <p className="itemRightContent">品名：雪域花园双面大方巾</p>
                                                <p className="itemRightContent">材质：100%重磅桑蚕丝素绉缎</p>
                                                <p className="itemRightContent">尺寸：110*110CM</p>
                                                <p className="itemRightContent">工艺：双面真丝印花手工卷边</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="productItemImageContent">
                                        <img className="productItemImage" src={p2}></img>
                                        <div className="productItemDetail">
                                            <div className="itemLeft">产品详情</div>
                                            <div className="itemRight">
                                                <p className="itemRightContent">品名：黄金花园双面大方巾</p>
                                                <p className="itemRightContent">材质：100%重磅桑蚕丝素绉缎</p>
                                                <p className="itemRightContent">尺寸：110*110CM</p>
                                                <p className="itemRightContent">工艺：双面真丝印花手工卷边</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="productItemIdeaContainer">
                                    <div className="itemIdeaLeft">设计理念</div>
                                    <div className="itemIdeaRight">
                                        <div className="itemIdeaContent1">
                                            黄金花园与雪域花园的图案设计围绕藏式园林展开，不同的配色彰显不同的气韵。黄金花园大面积运用浅金色，画面中心的琼楼砖瓦如玉璧，在100%桑蚕丝的质感衬托下光泽华美，凸显传统藏式审美中对金色的尊崇和追求。雪域花园以唐卡原画的配色为基础，致敬传统文化艺术，赋予丝绸更多历史与情感价值。
                                        </div>
                                        <div className="itemIdeaContent2">
                                            <img className="itemIdeaImage" src={p3}></img>
                                            <div className="itemIdeaImageDsp">
                                                设计团队提取传统唐卡中的建筑、云雾、植物等元素，组合出新的画面。画面中绿植丰茂，果实饱满，祥云环绕，寓意着富足美好、吉祥如意。
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default ProductDetail;
