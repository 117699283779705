import MyHeader from '../../../components/header';
import React, { ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Image, Button, Drawer, Space, Form, Col, Row, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import './index.less';

import original_1 from '../../../assets/pictures/0001-original.jpg';
import line_1 from '../../../assets/pictures/0001-line.png';
import vector_1 from '../../../assets/pictures/0001-vector.png';

import msgBg from '../../../assets/pictures/msgBg.png';

const DatabaseDL: React.FC = (): ReactElement => {
    const { state } = useLocation();
    const original = `https://tk-assets-1251826007.cos.ap-guangzhou.myqcloud.com/library/original/${state}-original.webp`;
    const line = `https://tk-assets-1251826007.cos.ap-guangzhou.myqcloud.com/library/line/${state}-line.png`;
    const vector = `https://tk-assets-1251826007.cos.ap-guangzhou.myqcloud.com/library/vector/${state}-vector.webp`;

    // 表单
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    // 下载
    const onOriginalDL = () => {
        console.log('Original');
    };

    const onLineDL = () => {
        console.log('Line');
    };

    const onVectorDL = () => {
        console.log('Vector');
    };

    const style: React.CSSProperties = {
        backgroundImage: `url(${msgBg})`,
    };

    return (
        <div className="databaseDL-Body" style={style}>
            <MyHeader current="item-2" />
            <div className="dataSetDLComponent">
                <div className="dlContainer">
                    <div className="dataSetGroup">
                        <div className="dataSetContent">
                            <Image src={original} alt="" className="dataSetImage" />
                        </div>
                        <div className="dataSetContent">
                            <Image src={line} alt="" className="dataSetImage" />
                        </div>
                        <div className="dataSetContent">
                            <Image src={vector} alt="" className="dataSetImage" />
                        </div>
                    </div>
                    <div className="dlbuttonGroup">
                        <div className="dlbutton">
                            <a href={original_1} download>
                                <Button
                                    type="primary"
                                    shape="round"
                                    icon={<DownloadOutlined />}
                                    size="large"
                                    onClick={onOriginalDL}
                                >
                                    原图下载
                                </Button>
                            </a>
                        </div>
                        <div className="dlbutton">
                            <a href={line_1} download>
                                <Button
                                    type="primary"
                                    shape="round"
                                    icon={<DownloadOutlined />}
                                    size="large"
                                    onClick={onLineDL}
                                >
                                    矢量图下载
                                </Button>
                            </a>
                        </div>
                        <div className="dlbutton">
                            <a href={vector_1} download>
                                <div className="dlbutton_tl">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        icon={<DownloadOutlined />}
                                        size="large"
                                        onClick={onVectorDL}
                                    >
                                        彩色矢量图下载
                                    </Button>
                                </div>
                            </a>
                            <a href="#">
                                <div className="dlbutton_tl">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        icon={<DownloadOutlined />}
                                        size="large"
                                        onClick={showDrawer}
                                    >
                                        eps文件下载
                                    </Button>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <Drawer
                    title="申请eps文件下载"
                    width={600}
                    onClose={onClose}
                    visible={visible}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={
                        <Space>
                            <Button onClick={onClose}>取消</Button>
                        </Space>
                    }
                >
                    <Form layout="vertical" hideRequiredMark onFinish={onFinish}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="姓名"
                                    rules={[{ required: true, message: '请输入您的姓名！' }]}
                                >
                                    <Input placeholder="输入姓名" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="mail"
                                    label="邮箱"
                                    rules={[{ required: true, message: '请输入您的邮箱！' }]}
                                >
                                    <Input placeholder="123@email.com" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="phone"
                                    label="手机"
                                    rules={[{ required: true, message: '请输入您的手机号！' }]}
                                >
                                    <Input placeholder="188********" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name="description"
                                    label="理由"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入内容！',
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={4} placeholder="申请理由" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        申请
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div>
        </div>
    );
};

export default DatabaseDL;
