/*
 * @Author:
 * @Date: 2022-07-19 09:22:33
 * @LastEditTime: 2023-03-14 17:20:22
 * @Description:
 */
import request from './request';

interface ILoginParams {
    username: string | number;
    password: string | number;
}

// 注册
export const RegisterApi = (params: ILoginParams) => request.post('/register', params);

// 登录
export const LoginApi = (params: ILoginParams) => request.post('/login', params);

// 获取用户信息
export const UserInfoApi = () => request.get('/info');

// 获取图文教程列表
export const ArticleListApi = () => request.get('/article');
// 根据id获取教程详情
export const getArticleByIdApi = (params: { id: number }) => request.get(`/article/id/${params.id}`);

// 获取数据集
export const LibraryListApi = () => request.get('/library');
