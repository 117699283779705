/*
 * @Author: 992526373@qq.com
 * @Date: 2022-07-19 09:22:42
 * @LastEditTime: 2024-05-13 09:58:42
 * @Description:
 */
import axios from 'axios';

// 配置项接口
interface AxiosOption {
    baseURL: string;
    timeout: number;
}

// 配置项
// const axiosOption: AxiosOption = {
//     baseURL: 'http://localhost:9000/web',
//     timeout: 5000,
// };
const axiosOption: AxiosOption = {
    baseURL: 'https://service-cm0gnbew-1251826007.gz.tencentapigw.com.cn/release/web',
    timeout: 5000,
};
// 创建一个单例
const instance = axios.create(axiosOption);

// 添加请求拦截器
instance.interceptors.request.use(
    function (config) {
        let token = localStorage.getItem('tk-auth-token');
        if (token) {
            config.headers = {
                'tk-auth-token': token,
            };
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    },
);

// 添加响应拦截器
instance.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        return response.data;
    },
    function (error) {
        // 对响应错误做点什么
        return Promise.reject(error);
    },
);

export default instance;
