/* eslint-disable @typescript-eslint/no-explicit-any */
import MyHeader from '../../components/header';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import Macy from 'macy';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { pageAnimationTitle, pageAnimationContent, cardAnimation, pageAnimation } from '../../utils/animations';
import './index.less';

import { LibraryListApi } from '../../request/api';

interface DataType {
    name: string;
    original: string;
}
/**
 * 数据集检索
 */
const onFinish = (values: any) => {
    console.log('Received values of form: ', values);
};

const options = [{ value: '无' }];

const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={value}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{ marginRight: 3 }}
        >
            {label}
        </Tag>
    );
};

const onTagChange = (value: any, options: any) => {
    console.log('tagChange');
    console.log(value);
    console.log(options);
};

/**
 * 瀑布流图片
 */
const macyOptions = {
    container: '#macy-grid',
    trueOrder: false,
    mobileFirst: false,
    waitForImages: false,
    margin: { x: 12, y: 12 },
    columns: 6,
    breakAt: {
        1000: 6,
        650: {
            margin: 20,
            columns: 2,
        },
    },
};

const DatabasePage: React.FC = (): ReactElement => {
    const [form] = Form.useForm();
    const [data, setData] = useState<DataType[]>([]);

    useEffect(() => {
        LibraryListApi().then((res: any) => {
            console.log(res);
            setData(res.data);
        });

        let masonry = new Macy(macyOptions);
        setTimeout(() => {
            masonry.recalculate();
        }, 3000);
    }, []);

    const navigate = useNavigate();

    const onImageClick = (e: any) => {
        console.log(e);
        navigate('/dbdownload', {
            state: e.target.alt,
        });
    };

    return (
        <div className="databaseDL-Body">
            <MyHeader current="item-2" />
            <motion.div
                className="databaseComponent"
                exit="exit"
                initial="initial"
                animate="animate"
                variants={pageAnimation}
            >
                <div className="databaseHeader">
                    <div className="databaseHeaderContainer">
                        <motion.div
                            className="databaseHeader-title"
                            exit="exit"
                            initial="hide"
                            animate="show"
                            variants={pageAnimationTitle}
                        >
                            青海民族文化矢量真值数据集
                        </motion.div>
                        <motion.div
                            className="databaseHeader-content"
                            exit="exit"
                            initial="hide"
                            animate="show"
                            variants={pageAnimationContent}
                        >
                            <p>
                                青海武汉理工大学文化科技融合产业技术研究院对已经完成矢量化转化的青海民族文化元素矢量数据集进行展示。这些资源主要分为矢量元素图像数据、轮廓提取数据集，以及整理对照的栅格图像文件三类。
                            </p>
                            <p>
                                借助先进的数字技术和手段对青海地区的文化资源进行记录和保存，形成特色民族文化矢量真值数据集，能够解决青海特色民族文化资源存在的零散性、不系统等问题，从而更好地整理、收集、留存相关信息，助推青海特色民族文化的整体性保护、研究和传承。
                            </p>
                            <p>
                                应用矢量真值数据集，能够创新现有文物资源的展现方式和应用范围。矢量数据的高适配性使其能够在多媒体上广泛运用，创新文化资源的呈现方式，达到传统保护方式所不能达到的展示与保真效果，可以更充分地发挥文化遗产的教育、传播和科普功能。
                            </p>
                            <p>
                                此外，数据集还能对开发、延伸青海特色民族文化产品和创新商业模式起到促进作用。通过创造性数据发掘和转化，把文化资源所蕴含的丰富价值内容与数据生产要素有机结合，能够实现创新发展，最终提升数字文化经济的整体价值。
                            </p>
                        </motion.div>
                    </div>
                </div>
                <div className="searchForm">
                    <Form form={form} name="advanced_search" className="ant-advanced-search-form" onFinish={onFinish}>
                        <Row gutter={24}>
                            <Form.Item name={`search`}>
                                <Col span={24}>
                                    <Input placeholder="在数据集中检索：" />
                                </Col>
                            </Form.Item>
                            <Button type="primary" htmlType="submit">
                                搜索
                            </Button>
                            <Button
                                style={{ margin: '0 8px' }}
                                onClick={() => {
                                    form.resetFields();
                                }}
                            >
                                清除
                            </Button>
                        </Row>
                        <Row>
                            <Form.Item name={`searchTags`}>
                                <Col span={24}>
                                    <Select
                                        mode="multiple"
                                        showArrow
                                        tagRender={tagRender}
                                        options={options}
                                        onChange={onTagChange}
                                        placeholder="使用标签检索：："
                                    />
                                </Col>
                            </Form.Item>
                        </Row>
                    </Form>
                </div>
                <div className="pictureContainer">
                    <div className="pictureGroup">
                        <motion.ul initial="hide" id="macy-grid" viewport={{ once: true }}>
                            {/* {data.map((data) => (
                            <li key={data.name}>
                                <img src={data.images.original} />
                            </li>
                        ))} */}

                            {data.map((data) => (
                                <motion.li variants={cardAnimation} whileInView="whileInView" key={data.name}>
                                    <img key={data.name} alt={data.name} src={data.original} onClick={onImageClick} />
                                </motion.li>
                            ))}

                            {}
                        </motion.ul>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default DatabasePage;
