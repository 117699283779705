import React, { ReactElement, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CarouselRef } from 'antd/lib/carousel';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Carousel, Timeline, Card, Col, Row, Button, Steps, Image, Form, Input } from 'antd';

import {
    CameraOutlined,
    GatewayOutlined,
    HighlightOutlined,
    ForwardOutlined,
    PhoneTwoTone,
    HomeTwoTone,
    MailTwoTone,
} from '@ant-design/icons';
// 组件
import MyHeader from './components/header';
import MyFooter from './components/footer';
import { pageAnimation } from './utils/animations';
// css
import './App.less';
// 静态资源
import original_1 from './assets/pictures/0001-original.jpg';
import line_1 from './assets/pictures/0001-line.png';
import vector_1 from './assets/pictures/0001-vector.png';
import original_2 from './assets/pictures/0019-original.jpg';
import line_2 from './assets/pictures/0019-line.png';
import vector_2 from './assets/pictures/0019-vector.png';
//
import banner1 from './assets/banner/banner1.png';
import banner2 from './assets/banner/banner2.png';
import banner3 from './assets/banner/banner3.png';
import banner4 from './assets/banner/banner4.png';
//
import product1 from './assets/product/product1.png';
import product2 from './assets/product/product2.png';
import product3 from './assets/product/product3.png';
import product4 from './assets/product/product4.png';
//
import msgBg from './assets/pictures/msgBg.png';

interface IState {
    username: string;
    player: string;
    avatar: string;
}

const carouselStyle: React.CSSProperties = {
    width: '1920px',
    height: '468px',
    color: '#fff',
    lineHeight: '468px',
    textAlign: 'center',
    background: '#364d79',
};

const { Meta } = Card;
const { Step } = Steps;

const App: React.FC = (): ReactElement => {
    const navigate = useNavigate();
    // 数据库轮播切换
    const carouselRef = useRef<CarouselRef>();

    const ondataBasePrev = () => {
        carouselRef.current?.prev();
    };

    const ondataBaseNext = () => {
        carouselRef.current?.next();
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const style: React.CSSProperties = {
        backgroundImage: `url(${msgBg})`,
    };

    // 页尾表单验证
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${name}不能为空!',
        types: {
            email: '不是一个有效的邮箱!',
        },
    };

    // 页尾表单提交
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFinish = (values: any) => {
        // console.log(process.env.SERVER_HOST_PORT);
        console.log(values);
    };

    // 路由
    const onProductClick = () => {
        navigate('/product');
    };

    const onCourseClick = () => {
        navigate('/course');
    };

    const onDatabaseClick = () => {
        navigate('/database');
    };

    return (
        <div className="appComponent">
            <MyHeader current="item-1" />
            <motion.div
                className="indexContainer"
                exit="exit"
                initial="initial"
                animate="animate"
                variants={pageAnimation}
            >
                {/* 轮播图组件 */}
                <div className="carousel">
                    <Carousel autoplay dots={{ className: 'carouselDots' }}>
                        <div>
                            <Image style={carouselStyle} src={banner1}></Image>
                        </div>
                        <div>
                            <Image style={carouselStyle} src={banner2}></Image>
                        </div>
                        <div>
                            <Image style={carouselStyle} src={banner3}></Image>
                        </div>
                        <div>
                            <Image style={carouselStyle} src={banner4}></Image>
                        </div>
                    </Carousel>
                </div>
                {/* 数据集组件 */}
                <div className="dataBaseContainer">
                    <div className="dataBaseHeader">
                        <div className="disTitle">青海民族文化矢量真值数据集</div>
                        <div className="disSummary">
                            <p className="disP">
                                青海武汉理工大学文化科技融合产业技术研究院在就计算机视觉算法优化的过程中，同时进行人工甄别与矢量数字化相结合的办法处理图像，提取单位元素，通过人工辅助计算机智能识别矢量化，人工校准矢量绘制是手工艺与计算机相结合产生的新时代融合型工艺，用计算机辅助人工，达到更高数字化资源产出水准，追求更高生产效率和更广泛的传播途径。
                            </p>
                        </div>
                    </div>
                    <nav className="carousel-box">
                        <a className="prev" onClick={ondataBasePrev}>
                            <i></i>
                        </a>
                        <a className="next" onClick={ondataBaseNext}>
                            <i></i>
                        </a>

                        <Carousel ref={carouselRef} dots={false}>
                            <div className="swiperSlide">
                                <Steps size="small" current={3}>
                                    <Step title="原图" icon={<CameraOutlined />} />
                                    <Step title="矢量线稿" icon={<GatewayOutlined />} />
                                    <Step title="上色" icon={<HighlightOutlined />} />
                                </Steps>
                                <div className="slide">
                                    <Image.PreviewGroup>
                                        <Image className="swiper-image" src={original_1} />
                                        <ForwardOutlined className="swiper-step" />
                                        <Image className="swiper-image" src={line_1} />
                                        <ForwardOutlined className="swiper-step" />
                                        <Image className="swiper-image" src={vector_1} />
                                    </Image.PreviewGroup>
                                </div>
                            </div>
                            <div className="swiperSlide">
                                <Steps size="small" current={3}>
                                    <Step title="原图" icon={<CameraOutlined />} />
                                    <Step title="矢量线稿" icon={<GatewayOutlined />} />
                                    <Step title="上色" icon={<HighlightOutlined />} />
                                </Steps>
                                <div className="slide">
                                    <Image.PreviewGroup>
                                        <Image className="swiper-image" src={original_2} />
                                        <ForwardOutlined className="swiper-step" />
                                        <Image className="swiper-image" src={line_2} />
                                        <ForwardOutlined className="swiper-step" />
                                        <Image className="swiper-image" src={vector_2} />
                                    </Image.PreviewGroup>
                                </div>
                            </div>
                        </Carousel>
                    </nav>
                    <div className="dataBaseButton">
                        <div>
                            <Button shape="round" size="middle" block onClick={onCourseClick}>
                                查看教程
                            </Button>
                        </div>
                        <div>
                            <Button shape="round" size="middle" block onClick={onDatabaseClick}>
                                数据集下载
                            </Button>
                        </div>
                    </div>
                </div>
                {/* 技术路线 */}
                <div className="tech-container">
                    <div className="techHeader">
                        <div className="disTitle">技术路线</div>
                        {/* <div className="disSummary">
                            <p className="disP">
                                藏文化旅游文创行业大多还停留在传统的实体交通与实体商业的经营模式，传播效率因地域距离而受限。自2019年新冠疫情以来，旅游业受到打击，人们的生活交流受到更多局限。当地特色民族文化艺术亟待数字化采集、处理、修复、存储、开放、开发、线上传播，当地旅游文化行业和文创产业也需要科学的线上传播方式助力，科技、文化、旅游融合发展。团队将帮助藏文化地区建立完善的馆藏数据库，帮助当地特色文化IP进行数字化产品研发、授权、传播推广、线上文创运营。
                            </p>
                        </div> */}
                    </div>
                    <div className="tech-content">
                        <div className="tech-item">
                            <div className="tech-title">科技结合传统文化</div>
                            <div className="tech-description">
                                运用数字化技术对当地民族特色展品、工艺品、美术品等进行数字化采集、存储、开发数字内容，永久保护保存。
                            </div>
                        </div>
                        <div className="tech-item">
                            <div className="tech-title">数字研发轻型文创</div>
                            <div className="tech-description">
                                运用二维及三维软件技术帮助民族文化单位进行特色产品数字化研发，投入智能制造，打造“研发在高原，制造在全国”的轻型环保文创产业模式。
                            </div>
                        </div>
                        <div className="tech-item">
                            <div className="tech-title">线下门店线上推广</div>
                            <div className="tech-description">
                                文创旅游纪念品逐渐个性化、体验化、网络社交化消费趋势。通过线上线下同时拓宽销售渠道。通过AR等各类体验型技术加持产品话题性，增强产品的社交价值，科技文化融合推进当地文创产业年产值。
                            </div>
                        </div>
                        <div className="tech-item">
                            <div className="tech-title">数字内容知识产权</div>
                            <div className="tech-description">
                                大量自有知识产权的数字内容、优秀民族文化数据库对外开放、授权、复用，能够更大范围激活文化经济，增强普及力度和受众面，带来网络流量价值。
                            </div>
                        </div>
                    </div>
                </div>
                {/* 产品组件 */}
                <div className="productContainer">
                    <div className="productHeader">
                        <div className="disTitle">特色民族文化矢量数据应用案例</div>
                        <div className="disSummary">
                            <p className="disP">
                                在武汉理工大学计算机及人工智能技术的支持下，结合历史悠久的精湛工艺、国际化设计美学、当代生产技术，保护、存储、再现、创新发展特色民族文化，激活数字经济及线上收入，打造“研发在高原、生产在全国”新体系，帮助当地企业、手工艺人的技术与科技结合，实现数字化、现代化转型。
                            </p>
                        </div>
                    </div>
                    <div className="productContent">
                        <div className="site-card-wrapper">
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Card cover={<img alt="特色民族文化艺术方巾" src={product1} />}>
                                        <Meta title="特色民族文化艺术方巾" />
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card cover={<img alt="特色民族文化原创手机壳" src={product2} />}>
                                        <Meta title="特色民族文化原创手机壳" />
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card cover={<img alt="特色民族文化真金马克杯" src={product3} />}>
                                        <Meta title="特色民族文化真金马克杯" />
                                    </Card>
                                </Col>
                                <Col span={8}>
                                    <Card cover={<img alt="特色民族文化唐卡故事胸针" src={product4} />}>
                                        <Meta title="特色民族文化唐卡故事胸针" />
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <div className="productButton">
                            <div>
                                <Button shape="round" size="middle" onClick={onProductClick} block>
                                    查看更多
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 团队介绍组件 */}
                {/* <div className="teamHeader">
                    <div>团队发展历程</div>
                </div>
                <div className="teamIntro">
                    <div className="introBox">
                        <Timeline>
                            <Timeline.Item>
                                2017年6月，我国首个藏文化陶瓷展馆在拉萨市柳梧新区的众创空间正式开放，雅鲁团队结合特色民族文化图像矢量化处理技术、三维扫描技术、三维打印等数字化研发技术，开发出的藏文化陶瓷系列产品，在文化科技融合的基础上，对民族特色文化既有保护性传承，又符合现代社会的消费需求。2017年11月，团队研发的藏文化元素系列产品跟随西藏代表团参加了2017年中国国际高新技术成果交易会，进驻一带一路展馆参加展出。项目组采用数字化的、设计创新的、结合高科技生产工艺的文化产品及西藏文化元素个性化定制平台，受到市场广泛关注。
                            </Timeline.Item>
                            <Timeline.Item>
                                2018年，雅鲁团队在深圳高交会发布藏文化数字化印花产品、矢量数字化研发烫金唐卡摆盘等；在广交会发布藏文化数字化研发投入智能化生产的“白塔”系列产品，获得广东省及西藏自治区领导莅临指导；在上海同济大学与佛罗伦萨艺术馆、上海美术馆、日本雪花公司共同进行文化数字化讲座，以《藏文化数字化保护与传承》为宣讲主题进行学术与技术分享；注册商标、专利、登记产品著作权，申请多项相关外观专利及设计版权。
                            </Timeline.Item>
                            <Timeline.Item>
                                2019年，与西藏科技厅、西藏旅发委合作搭建的“数字西藏”展厅入选第二届“数字中国”建设成果展览会，全面展示近年来藏文化数字建设实践和创新成果。其中雅鲁团队参与研发的藏文化数字化基因库、藏文化数字化研发文创产品等吸引了众多参观者。另参展深圳文博会，用西藏特色陶土青稞酒壶3D扫描模型、3D打印结构、3D建模研发流程及AR体验等方式，展示了文化数字化关键技术的最新成果。运用大数据、增强现实、交互系统、以虚实结合沉浸式交互体验，普及展示了民族文化数字化进程，取得了卓越成效，同年获批多项软件著作权。
                            </Timeline.Item>
                        </Timeline>
                    </div>
                </div> */}
                {/* 联系方式 */}
                <div className="contacts-container" style={style}>
                    <div className="contacts-box">
                        <div className="contacts-info">
                            <div className="contacts-title">联系我们</div>
                            <address className="address">
                                <div className="address-item">
                                    <HomeTwoTone className="address-icon" />
                                    地址:&nbsp;青海武汉理工大学文化科技融合产业技术研究院
                                </div>
                                <div className="address-item">
                                    <PhoneTwoTone className="address-icon" />
                                    电话:&nbsp;027-87780029
                                </div>
                                <div className="contacts-title2">问题反馈</div>
                                {/* <div className="address-item">
                                    <MailTwoTone className="address-icon" />
                                    邮箱:&nbsp;&nbsp;810724414@email.com
                                </div> */}
                            </address>
                        </div>
                        <div className="contacts-form">
                            <Form
                                {...layout}
                                name="nest-messages"
                                onFinish={onFinish}
                                validateMessages={validateMessages}
                            >
                                <Form.Item
                                    name={['姓名']}
                                    rules={[{ required: true }]}
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <Input placeholder="姓名：" />
                                </Form.Item>
                                <Form.Item
                                    name={['电话']}
                                    rules={[
                                        {
                                            required: true,
                                            pattern:
                                                /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
                                            message: '请输入正确的手机号!',
                                        },
                                    ]}
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                                >
                                    <Input placeholder="联系电话：" />
                                </Form.Item>
                                <Form.Item
                                    name={['邮箱']}
                                    rules={[{ required: true, type: 'email' }]}
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                                >
                                    <Input placeholder="邮箱：" />
                                </Form.Item>
                                <Form.Item
                                    name={['主题']}
                                    rules={[{ required: true }]}
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '16px' }}
                                >
                                    <Input placeholder="反馈主题：" />
                                </Form.Item>
                                <Form.Item name={['内容']} rules={[{ required: true }]}>
                                    <Input.TextArea placeholder="请输入您想要反馈的具体内容：" />
                                </Form.Item>
                                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                                    <Button type="primary" htmlType="submit">
                                        发送
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </div>
                {/* 友情链接 */}
                <div className="link-container">
                    <p>
                        <a>青海科技厅</a>
                        <a>青海文化厅</a>
                        <a>武汉理工大学</a>
                        <a>海东市工业园</a>
                    </p>
                </div>
            </motion.div>
            <MyFooter />
        </div>
    );
};

// react redux
const mapStateToProps = (state: IState) => {
    return {
        userName: state.username,
        avatar: state.avatar,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        // 修改react-redux的用户名
        changeUsername<T>(value: T): void {
            dispatch({ type: 'ChangeUsername', value });
        },
        // 修改react-redux的密码
        changePlayer<T>(value: T): void {
            dispatch({ type: 'ChangePlayer', value });
        },
        // 修改react-redux的头像
        changeAvatar<T>(value: T): void {
            dispatch({ type: 'ChangeAvatar', value });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
