/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { Button, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import { LoginApi } from './request/api';
import MyFooter from './components/footer';

import './Login.less';
import background from './assets/pictures/bg.png';
import { Header } from 'antd/lib/layout/layout';

interface ILoginParams {
    username: string | number;
    password: string | number;
}

interface IRes {
    errCode?: number;
    message?: string;
    data?: any;
}

const Login: React.FC = (): ReactElement => {
    // const [form] = Form.useForm<{ user: string; password: string }>();
    const navigate = useNavigate();
    const onFinish = (values: ILoginParams) => {
        LoginApi(values).then((res: IRes) => {
            if (res.errCode == 0) {
                // 提示成功
                message.success(res.message, 1.5);
                // 保存token
                localStorage.setItem('username', res.data.username);
                localStorage.setItem('tk-auth-token', res.data['tk-auth-token']);
                localStorage.setItem('avatar', res.data.avatar);
                localStorage.setItem('logintype', 'true');

                // 跳转路径
                setTimeout(() => {
                    navigate('/index');
                }, 1500);
            } else {
                message.error(res.message, 1.5);
            }
        });
    };

    const onFinishFailed = (errorInfo: unknown) => {
        console.log('Failed:', errorInfo);
    };

    const style: React.CSSProperties = {
        backgroundImage: `url(${background})`,
    };

    return (
        <div className="loginBody" style={style}>
            <div className="loginBox">
                <div className="loginTitle">欢迎登录</div>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                >
                    <Form.Item name="username" rules={[{ required: true, message: '请输入用户名!' }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入用户名：" />
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="请输入密码："
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            登录
                        </Button>
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Link to="/register">没账号？立即注册</Link>
                    </Form.Item>
                </Form>
            </div>
            <Header>
                <div className="title">
                    {/* <div className="logo" /> */}
                    <div className="name">特色民族文化矢量标注流程及矢量真值数据集平台</div>
                </div>
            </Header>
            <div className="loginContainer"></div>
            <MyFooter />
        </div>
    );
};

export default Login;
