/*
 * @Author:
 * @Date: 2022-12-09 10:19:57
 * @LastEditTime: 2022-12-09 16:40:45
 * @Description:
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MyHeader from '../../components/header';
import React, { ReactElement } from 'react';
import './index.less';
import { motion } from 'framer-motion';

import { Button } from 'antd';
import { pageAnimationTitle, pageAnimationContent, pageAnimation } from '../../utils/animations';

import p1 from '../../assets/product/product1.png';
import p2 from '../../assets/product/product2.png';
import p3 from '../../assets/product/product3.png';
import p4 from '../../assets/product/product4.png';
import background from '../../assets/pictures/bg.png';
import MyFooter from '../../components/footer';
import { useNavigate } from 'react-router-dom';

const style: React.CSSProperties = {
    backgroundImage: `url(${background})`,
};

const ProductPage: React.FC = (): ReactElement => {
    const navigate = useNavigate();
    const onProductClick = () => {
        navigate('/productdetail');
    };

    return (
        <div className="productBody" style={style}>
            <MyHeader current="item-4" />
            <motion.div
                className="productComponent"
                exit="exit"
                initial="initial"
                animate="animate"
                variants={pageAnimation}
            >
                <div className="productHeader">
                    <div className="productHeaderContainer">
                        <motion.div
                            className="productHeader-title"
                            exit="exit"
                            initial="hide"
                            animate="show"
                            variants={pageAnimationTitle}
                        >
                            特色民族文化矢量数据应用案例
                        </motion.div>
                        <motion.div
                            className="productHeader-content"
                            exit="exit"
                            initial="hide"
                            animate="show"
                            variants={pageAnimationContent}
                        >
                            <p>
                                特色民族文化矢量数据应用案例可查看多种提炼特色民族文化艺术符号后进行文化数字化资源复用的代表性文创产品，用基因库里的矢量文件进行二次创作、资源复用，实现产品现代化生产。案例展示了通过训练设计师学习使用数据库，运用已有元素进行大量、多品类文创产品完整的设计流程，并详细说明了设计思路与方向，通过图文展示、产品尺寸与材质等信息的说明、对矢量元素的合理分类来直观简单的进行呈现，为中小企业对特色民族我文化矢量真值数据集的使用提供助力。
                            </p>
                        </motion.div>
                    </div>
                </div>
                <motion.div
                    exit="exit"
                    initial="hide"
                    animate="show"
                    variants={pageAnimationContent}
                    className="productList"
                >
                    <div className="product">
                        <img className="productImg" src={p1} />
                        <div className="productTitle">特色名族文化艺术方巾</div>
                        <Button className="productBtn" onClick={onProductClick}>
                            查看更多
                        </Button>
                    </div>
                    <div className="product">
                        <img className="productImg" src={p2} />
                        <div className="productTitle">特色名族文化手机壳</div>
                        <Button className="productBtn" onClick={onProductClick}>
                            查看更多
                        </Button>
                    </div>
                    <div className="product">
                        <img className="productImg" src={p3} />
                        <div className="productTitle">特色名族文化真金印花马克杯</div>
                        <Button className="productBtn" onClick={onProductClick}>
                            查看更多
                        </Button>
                    </div>
                    <div className="product">
                        <img className="productImg" src={p4} />
                        <div className="productTitle">特色名族文化金属徽章</div>
                        <Button className="productBtn" onClick={onProductClick}>
                            查看更多
                        </Button>
                    </div>
                </motion.div>
            </motion.div>
            <MyFooter />
        </div>
    );
};

export default ProductPage;
